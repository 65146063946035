import { For, Show } from "solid-js";
import { urlRs } from "~/utils/url";
import { hasNoEscape } from "~/utils/no_escape";

import type { BreadcrumbItem } from "~/types/breadcrumb";

import "./Breadcrumb.css";

type BreadCrumbProps = {
  items: BreadcrumbItem[];
};

export default function Breadcrumb(props: BreadCrumbProps) {
  return (
    <>
      <Show when={!hasNoEscape()}>
        <section class="breadcrumb" data-test="breadcrumbs">
          <nav
            class="content-part"
            role="navigation"
            aria-labelledby="system-breadcrumb"
          >
            <ol>
              <li data-test="level-0">
                <a href={urlRs("front", "/")}>Accueil</a>
              </li>
              <For each={props.items}>
                {(item, index) => (
                  <Show
                    keyed
                    when={item.href}
                    fallback={
                      <li data-test={`level-${index() + 1}`}>
                        <span>{item.text}</span>
                      </li>
                    }
                  >
                    <li data-test={`level-${index() + 1}`}>
                      <a href={item.href} title={item.title}>
                        {item.text}
                      </a>
                    </li>
                  </Show>
                )}
              </For>
            </ol>
          </nav>
        </section>
      </Show>
    </>
  );
}
